// 默认路由
const defaultRouter = {
  path: "home",
  name: "supplier.home",
  component: () => import("@/views/supplier/home/index.vue"),
  meta: {
    title: "首页",
    auth: {
      required: true,
      header: false,
      list: [],
    },
  },
};
export default {
  default: defaultRouter,
  route: [
    defaultRouter,
    {
      path: "productManage",
      name: "supplier.productManage",
      component: () => import("@/views/supplier/productManage/index.vue"),
      meta: {
        title: "产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "analysisChart",
      name: "supplier.analysisChart",
      component: () => import("@/views/supplier/analysisChart/index.vue"),
      meta: {
        title: "数据分析",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "productManageSpecialty",
      name: "supplier.productManageSpecialty",
      component: () => import("@/views/supplier/productManageSpecialty/index.vue"),
      meta: {
        title: "文创产品",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "logisticsTemplate",
      name: "supplier.logisticsTemplate",
      component: () => import("@/views/supplier/logisticsTemplate/index.vue"),
      meta: {
        title: "物流模板",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageCombination",
      name: "supplier.productManageCombination",
      component: () => import("@/views/supplier/productManageCombination/index.vue"),
      meta: {
        title: "组合商品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "productManageLine",
      name: "supplier.productManageLine",
      component: () => import("@/views/supplier/productManageLine/index.vue"),
      meta: {
        title: "线路产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageRestaurant",
      name: "supplier.productManageRestaurant",
      component: () => import("@/views/supplier/productManageRestaurant/index.vue"),
      meta: {
        title: "餐饮产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "fleetManagement",
      name: "supplier.fleetManagement",
      component: () => import("@/views/supplier/fleetManagement/index.vue"),
      meta: {
        title: "车队管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageFleet",
      name: "supplier.productManageFleet",
      component: () => import("@/views/supplier/productManageFleet/index.vue"),
      meta: {
        title: "车队产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageHotel",
      name: "supplier.productManageHotel",
      component: () => import("@/views/supplier/productManageHotel/index.vue"),
      meta: {
        title: "酒店产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productGroup",
      name: "supplier.productGroup",
      component: () => import("@/views/supplier/productGroup/index.vue"),
      meta: {
        title: "产品组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "ticketDocking",
      name: "supplier.ticketDocking",
      component: () => import("@/views/supplier/ticketDocking/index.vue"),
      meta: {
        title: "票务系统对接",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorDocking",
      name: "supplier.distributorDocking",
      component: () => import("@/views/supplier/distributorDocking/index.vue"),
      meta: {
        title: "分销商对接配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "inventoryManage",
      name: "supplier.inventoryManage",
      component: () => import("@/views/supplier/inventoryManage/index.vue"),
      meta: {
        title: "库存管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "inventoryCalendar",
      name: "supplier.inventoryCalendar",
      component: () => import("@/views/supplier/inventoryCalendar/index.vue"),
      meta: {
        title: "库存日历",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "saleChannel",
      name: "supplier.saleChannel",
      component: () => import("@/views/supplier/saleChannel/index.vue"),
      meta: {
        title: "分销商渠道",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "saleCodePrice",
      name: "supplier.saleCodePrice",
      component: () => import("@/views/supplier/saleCodePrice/index.vue"),
      meta: {
        title: "销码价格列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "saleCodeCalendar",
      name: "supplier.saleCodeCalendar",
      component: () => import("@/views/supplier/saleCodeCalendar/index.vue"),
      meta: {
        title: "渠道价格日历",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "refundRule",
      name: "supplier.refundRule",
      component: () => import("@/views/supplier/refundRule/index.vue"),
      meta: {
        title: "退票规则管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "blackWhite",
      name: "supplier.blackWhite",
      component: () => import("@/views/supplier/blackWhite/index.vue"),
      meta: {
        title: "我的黑白名单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "whiteGroup",
      name: "supplier.whiteGroup",
      component: () => import("@/views/supplier/whiteGroup/index.vue"),
      meta: {
        title: "我的白名单组",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "wechatBinding",
      name: "supplier.wechatBinding",
      component: () => import("@/views/supplier/wechatBinding/index.vue"),
      meta: {
        title: "绑定微信",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "receivedMessage",
      name: "supplier.receivedMessage",
      component: () => import("@/views/supplier/receivedMessage/index.vue"),
      meta: {
        title: "已收消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "alreadyMessage",
      name: "supplier.alreadyMessage",
      component: () => import("@/views/supplier/alreadyMessage/index.vue"),
      meta: {
        title: "已发消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "msgTemplate",
      name: "supplier.msgTemplate",
      component: () => import("@/views/supplier/msgTemplate/index.vue"),
      meta: {
        title: "消息组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "user",
      name: "supplier.user",
      component: () => import("@/views/supplier/user/index.vue"),
      meta: {
        title: "用户列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "invoiceApply",
      name: "supplier.invoiceApply",
      component: () => import("@/views/supplier/invoiceApply/index.vue"),
      meta: {
        title: "发票审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "creditAccount",
      name: "supplier.creditAccount",
      component: () => import("@/views/supplier/creditAccount/index.vue"),
      meta: {
        title: "授信账户管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "creditAccountRecord",
      name: "supplier.creditAccountRecord",
      component: () => import("@/views/supplier/creditAccountRecord/index.vue"),
      meta: {
        title: "授信账户收支",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "transactionRecords",
      name: "supplier.transactionRecords",
      component: () => import("@/views/supplier/transactionRecords/index.vue"),
      meta: {
        title: "交易记录查询",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "youcaiAccountRecord",
      name: "supplier.youcaiAccountRecord",
      component: () => import("@/views/supplier/youcaiAccountRecord/index.vue"),
      meta: {
        title: "账户收支明细",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldOrder",
      name: "supplier.soldOrder",
      component: () => import("@/views/supplier/soldOrder/index.vue"),
      meta: {
        title: "已售订单列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "checkPendingRefundTicket",
      name: "supplier.checkPendingRefundTicket",
      component: () => import("@/views/supplier/checkPendingRefundTicket/index.vue"),
      meta: {
        title: "待审核退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "refundTicket",
      name: "supplier.refundTicket",
      component: () => import("@/views/supplier/refundTicket/index.vue"),
      meta: {
        title: "退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "info",
      name: "supplier.info",
      component: () => import("@/views/supplier/info/index.vue"),
      meta: {
        title: "个人信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "smsAccount",
      name: "supplier.smsAccount",
      component: () => import("@/views/supplier/smsAccount/index.vue"),
      meta: {
        title: "短信账户",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "orderSmsTemplate",
      name: "supplier.orderSmsTemplate",
      component: () => import("@/views/supplier/orderSmsTemplate/index.vue"),
      meta: {
        title: "短信模版管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "youcaiAccount",
      name: "supplier.youcaiAccount",
      component: () => import("@/views/supplier/youcaiAccount/index.vue"),
      meta: {
        title: `账户管理`,
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "roleGroup",
      name: "supplier.roleGroup",
      component: () => import("@/views/supplier/roleGroup/index.vue"),
      meta: {
        title: "角色组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "corpInfo",
      name: "supplier.corpInfo",
      component: () => import("@/views/supplier/corpInfo/index.vue"),
      meta: {
        title: "企业信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "paymentConfig",
      name: "supplier.paymentConfig",
      component: () => import("@/views/supplier/paymentConfig/index.vue"),
      meta: {
        title: "支付配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "journal",
      name: "supplier.journal",
      component: () => import("@/views/supplier/journal/index.vue"),
      meta: {
        title: "操作日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowSalesOrder",
      name: "supplier.windowSalesOrder",
      component: () => import("@/views/supplier/windowSalesOrder/index.vue"),
      meta: {
        title: "窗口售票订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowMembershipCard",
      name: "supplier.windowMembershipCard",
      component: () => import("@/views/supplier/windowMembershipCard/index.vue"),
      meta: {
        title: "会员卡管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowUserMembershipCard",
      name: "supplier.windowUserMembershipCard",
      component: () => import("@/views/supplier/windowUserMembershipCard/index.vue"),
      meta: {
        title: "用户会员卡列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowExchangeVIP",
      name: "supplier.windowExchangeVIP",
      component: () => import("@/views/supplier/windowExchangeVIP/index.vue"),
      meta: {
        title: "会员卡兑换",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowRedeemTickets",
      name: "supplier.windowRedeemTickets",
      component: () => import("@/views/supplier/windowRedeemTickets/index"),
      meta: {
        title: "会员兑换门票",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowVipWriteOff",
      name: "supplier.windowVipWriteOff",
      component: () => import("@/views/supplier/windowVipWriteOff/index"),
      meta: {
        title: "会员卡核销列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    // {
    //   path: 'windowReport',
    //   name: 'supplier.windowReport',
    //   component: () => import('@/views/supplier/windowReport/index'),
    //   meta: {
    //     title: "景区表报",
    //     auth: {
    //       required: true,
    //       header: false,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: "windowBusinessReport",
      name: "supplier.windowBusinessReport",
      component: () => import("@/views/supplier/windowBusinessReport/index"),
      meta: {
        title: "营业报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowTicketCheckReport",
      name: "supplier.windowTicketCheckReport",
      component: () => import("@/views/supplier/windowTicketCheckReport/index"),
      meta: {
        title: "检票报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    // {
    //   path: 'windowReportForm',
    //   name: 'supplier.windowReportForm',
    //   component: () => import('@/views/supplier/windowReportForm/index'),
    //   meta: {
    //     title: "营业报表模板导出",
    //     auth: {
    //       required: true,
    //       header: true,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: "windowReportTemplate",
      name: "supplier.windowReportTemplate",
      component: () => import("@/views/supplier/windowReportTemplate/index"),
      meta: {
        title: "报表模板列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "conductorReport",
      name: "supplier.conductorReport",
      component: () => import("@/views/supplier/windowReportTemplate/conductorReport"),
      meta: {
        title: "报表", // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "channelReport",
      name: "supplier.channelReport",
      component: () => import("@/views/supplier/windowReportTemplate/channelReport"),
      meta: {
        title: "报表", // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "report",
      name: "supplier.report",
      component: () => import("@/views/supplier/windowReportTemplate/report"),
      meta: {
        title: "报表", // 自定义营业报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowWriteOff",
      name: "supplier.windowWriteOff",
      component: () => import("@/views/supplier/windowWriteOff/index"),
      meta: {
        title: "核销记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowTicketExchangeTicket",
      name: "supplier.windowTicketExchangeTicket",
      component: () => import("@/views/supplier/windowTicketExchangeTicket/index"),
      meta: {
        title: "核销码兑换门票",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowExchangeRecord",
      name: "supplier.windowExchangeRecord",
      component: () => import("@/views/supplier/windowExchangeRecord/index.vue"),
      meta: {
        title: "会员卡兑换记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowVipExchangeTicket",
      name: "supplier.windowVipExchangeTicket",
      component: () => import("@/views/supplier/windowVipExchangeTicket/index.vue"),
      meta: {
        title: "会员兑换门票记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowSalesRefundHistory",
      name: "supplier.windowSalesRefundHistory",
      component: () => import("@/views/supplier/windowSalesRefundHistory/index.vue"),
      meta: {
        title: "退票历史",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    // {
    //   path: 'windowSalesRefund',
    //   name: 'supplier.windowSalesRefund',
    //   component: () => import('@/views/supplier/windowSalesRefund/index.vue'),
    //   meta: {
    //     auth: {
    //       required: true,
    //       header: true,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: "windowSales",
      name: "supplier.windowSales",
      component: () => import("@/views/supplier/windowSales/index.vue"),
      meta: {
        title: "窗口售票",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "theaterSales",
      name: "supplier.theaterSales",
      component: () => import("@/views/supplier/theaterSales/index.vue"),
      meta: {
        title: "剧场售票",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "windowFace",
      name: "supplier.windowFace",
      component: () => import("@/views/supplier/windowFace/index.vue"),
      meta: {
        title: "人脸机管理",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "windowFaceManage",
      name: "supplier.windowFaceManage",
      component: () => import("@/views/supplier/windowFaceManage/index.vue"),
      meta: {
        title: "人脸管理",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "windowTicketingGroup",
      name: "supplier.windowTicketingGroup",
      component: () => import("@/views/supplier/windowTicketingGroup/index.vue"),
      meta: {
        title: "售票组权限管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowVipGroup",
      name: "supplier.windowVipGroup",
      component: () => import("@/views/supplier/windowVipGroup/index.vue"),
      meta: {
        title: "会员卡分组",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowGate",
      name: "supplier.windowGate",
      component: () => import("@/views/supplier/windowGate/index.vue"),
      meta: {
        title: "闸机管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowHardware",
      name: "supplier.windowHardware",
      component: () => import("@/views/supplier/windowHardware/index.vue"),
      meta: {
        title: "硬件管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowPrinter",
      name: "supplier.windowPrinter",
      component: () => import("@/views/supplier/windowPrinter/index.vue"),
      meta: {
        title: "打印机管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowTicketChecking",
      name: "supplier.windowTicketChecking",
      component: () => import("@/views/supplier/windowTicketChecking/index.vue"),
      meta: {
        title: "检票组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "uniappCoupon",
      name: "supplier.uniappCoupon",
      component: () => import("@/views/supplier/uniappCoupon/index.vue"),
      meta: {
        title: "优惠券管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "specialtyOrder",
      name: "supplier.specialtyOrder",
      component: () => import("@/views/supplier/specialtyOrder/index.vue"),
      meta: {
        title: "特产订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "theaterZone",
      name: "supplier.theaterZone",
      component: () => import("@/views/supplier/theaterZone/index.vue"),
      meta: {
        title: "剧场区域",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "theaterSessions",
      name: "supplier.theaterSessions",
      component: () => import("@/views/supplier/theaterSessions/index.vue"),
      meta: {
        title: "剧场场次",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "theaterGroup",
      name: "supplier.theaterGroup",
      component: () => import("@/views/supplier/theaterGroup/index.vue"),
      meta: {
        title: "剧场区域组",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "theaterSeat",
      name: "supplier.theaterSeat",
      component: () => import("@/views/supplier/theaterSeat/index.vue"),
      meta: {
        title: "剧场座位",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "theaterSeatImg",
      name: "supplier.theaterSeatImg",
      component: () => import("@/views/supplier/theaterSeatImg/index.vue"),
      meta: {
        title: "剧场座位图",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiCardManage",
      name: "supplier.skiCardManage",
      component: () => import("@/views/supplier/skiCardManage/index.vue"),
      meta: {
        title: "开卡管理",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },

    {
      path: "skiCoachCardManage",
      name: "supplier.skiCoachCardManage",
      component: () => import("@/views/supplier/skiCoachCardManage/index.vue"),
      meta: {
        title: "教练卡管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiConsumptionCardManage",
      name: "supplier.skiConsumptionCardManage",
      component: () => import("@/views/supplier/skiConsumptionCardManage/index.vue"),
      meta: {
        title: "消费卡管理",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "skiLease",
      name: "supplier.skiLease",
      component: () => import("@/views/supplier/skiLease/index.vue"),
      meta: {
        title: "雪具租赁",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "skiCourse",
      name: "supplier.skiCourse",
      component: () => import("@/views/supplier/skiCourse/index.vue"),
      meta: {
        title: "课程销售",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "skiCardRefund",
      name: "supplier.skiCardRefund",
      component: () => import("@/views/supplier/skiCardRefund/index.vue"),
      meta: {
        title: "退卡管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiProductReturn",
      name: "supplier.skiProductReturn",
      component: () => import("@/views/supplier/skiProductReturn/index.vue"),
      meta: {
        title: "租赁物品退换",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiSoldOrderLease",
      name: "supplier.skiSoldOrderLease",
      component: () => import("@/views/supplier/skiSoldOrderLease/index.vue"),
      meta: {
        title: "租赁订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiSoldOrderCourse",
      name: "supplier.skiSoldOrderCourse",
      component: () => import("@/views/supplier/skiSoldOrderCourse/index.vue"),
      meta: {
        title: "课程订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiSettlementLog",
      name: "supplier.skiSettlementLog",
      component: () => import("@/views/supplier/skiSettlementLog/index.vue"),
      meta: {
        title: "结算卡日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiExpenseLog",
      name: "supplier.skiExpenseLog",
      component: () => import("@/views/supplier/skiExpenseLog/index.vue"),
      meta: {
        title: "消费卡日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiExchange",
      name: "supplier.skiExchange",
      component: () => import("@/views/supplier/skiExchange/index.vue"),
      meta: {
        title: "雪具兑换",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiProductTimeout",
      name: "supplier.skiProductTimeout",
      component: () => import("@/views/supplier/skiProductTimeout/index.vue"),
      meta: {
        title: "超时补费",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiCoachCardStatus",
      name: "supplier.skiCoachCardStatus",
      component: () => import("@/views/supplier/skiCoachCardStatus/index.vue"),
      meta: {
        title: "教练状态",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiCoachOverTime",
      name: "supplier.skiCoachOverTime",
      component: () => import("@/views/supplier/skiCoachOverTime/index.vue"),
      meta: {
        title: "教练结时",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiTicketTimeout",
      name: "supplier.skiTicketTimeout",
      component: () => import("@/views/supplier/skiTicketTimeout/index.vue"),
      meta: {
        title: "门票超时补费",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiSoldOrderTicket",
      name: "supplier.skiSoldOrderTicket",
      component: () => import("@/views/supplier/skiSoldOrderTicket/index.vue"),
      meta: {
        title: "雪场门票订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "skiAreaProductExchange",
      name: "supplier.skiAreaProductExchange",
      component: () => import("@/views/supplier/skiAreaProductExchange/index.vue"),
      meta: {
        title: "雪具兑换",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "guideCommission",
      name: "supplier.guideCommission",
      component: () => import("@/views/supplier/skiReportForms/guideCommission.vue"),
      meta: {
        title: "导游反佣报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "coachCommission",
      name: "supplier.coachCommission",
      component: () => import("@/views/supplier/skiReportForms/coachCommission.vue"),
      meta: {
        title: "教练反佣报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "ticketForm",
      name: "supplier.ticketForm",
      component: () => import("@/views/supplier/skiReportForms/ticketForm.vue"),
      meta: {
        title: "滑雪门票报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "leaseForm",
      name: "supplier.leaseForm",
      component: () => import("@/views/supplier/skiReportForms/leaseForm.vue"),
      meta: {
        title: "租赁报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
  ],
};
