// 默认路由
const defaultRouter = {
  path: "home",
  name: "general.home",
  component: () => import("@/views/general/home/index.vue"),
  meta: {
    title: "首页",
    auth: {
      required: true,
      header: false,
      list: [],
    },
  },
};
export default {
  default: defaultRouter,
  route: [
    defaultRouter,
    {
      path: "supplier",
      name: "general.supplier",
      component: () => import("@/views/general/supplier/index.vue"),
      meta: {
        title: "供应商管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "scenic",
      name: "general.scenic",
      component: () => import("@/views/general/scenic/index.vue"),
      meta: {
        // title: "景区管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "hotel",
      name: "general.hotel",
      component: () => import("@/views/general/hotel/index.vue"),
      meta: {
        title: "酒店管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributor",
      name: "general.distributor",
      component: () => import("@/views/general/distributor/index.vue"),
      meta: {
        title: "分销商管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "admin",
      name: "general.admin",
      component: () => import("@/views/general/admin/index.vue"),
      meta: {
        title: "审核人员管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "submitAdmin",
      name: "general.submitAdmin",
      component: () => import("@/views/general/submitAdmin/index.vue"),
      meta: {
        title: "提交人员管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "menuTree",
      name: "general.menuTree",
      component: () => import("@/views/general/menuTree/index.vue"),
      meta: {
        title: "菜单树管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "journal",
      name: "general.journal",
      component: () => import("@/views/general/journal/index.vue"),
      meta: {
        title: "操作日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "loginLog",
      name: "general.loginLog",
      component: () => import("@/views/general/loginLog/index.vue"),
      meta: {
        title: "登录日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "roleGroup",
      name: "general.roleGroup",
      component: () => import("@/views/general/roleGroup/index.vue"),
      meta: {
        title: "角色组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "supplierAudit",
      name: "general.supplierAudit",
      component: () => import("@/views/general/supplierAudit/index.vue"),
      meta: {
        title: "供应商审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorAudit",
      name: "general.distributorAudit",
      component: () => import("@/views/general/distributorAudit/index.vue"),
      meta: {
        title: "分销商审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "checkPending",
      name: "general.checkPending",
      component: () => import("@/views/general/checkPending/index.vue"),
      meta: {
        title: "待审核列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "corpCheck",
      name: "general.corpCheck",
      component: () => import("@/views/general/corpCheck/index.vue"),
      meta: {
        title: "企业信息变更申请",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "ticketDocking",
      name: "general.ticketDocking",
      component: () => import("@/views/general/ticketDocking/index.vue"),
      meta: {
        title: "票务系统对接",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsTicket",
      name: "general.productsTicket",
      component: () => import("@/views/general/productsTicket/index.vue"),
      meta: {
        title: "门票产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsCombination",
      name: "general.productsCombination",
      component: () => import("@/views/general/productsCombination/index.vue"),
      meta: {
        title: "组合商品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsFleet",
      name: "general.productsFleet",
      component: () => import("@/views/general/productsFleet/index.vue"),
      meta: {
        title: "车队管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsHotel",
      name: "general.productsHotel",
      component: () => import("@/views/general/productsHotel/index.vue"),
      meta: {
        title: "酒店管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsLine",
      name: "general.productsLine",
      component: () => import("@/views/general/productsLine/index.vue"),
      meta: {
        title: "线路管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsRestaurant",
      name: "general.productsRestaurant",
      component: () => import("@/views/general/productsRestaurant/index.vue"),
      meta: {
        title: "餐饮管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsSpecialty",
      name: "general.productsSpecialty",
      component: () => import("@/views/general/productsSpecialty/index.vue"),
      meta: {
        title: "特产管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "refundTicket",
      name: "general.refundTicket",
      component: () => import("@/views/general/refundTicket/index.vue"),
      meta: {
        title: "退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "checkPendingRefundTicket",
      name: "general.checkPendingRefundTicket",
      component: () => import("@/views/general/checkPendingRefundTicket/index.vue"),
      meta: {
        title: "待审核退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldOrder",
      name: "general.soldOrder",
      component: () => import("@/views/general/soldOrder/index.vue"),
      meta: {
        title: "已售订单列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "creditAccount",
      name: "general.creditAccount",
      component: () => import("@/views/general/creditAccount/index.vue"),
      meta: {
        title: "备用金账户信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "creditAccountRecord",
      name: "general.creditAccountRecord",
      component: () => import("@/views/general/creditAccountRecord/index.vue"),
      meta: {
        title: "备用金资金记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "supplierAccount",
      name: "general.supplierAccount",
      component: () => import("@/views/general/supplierAccount/index.vue"),
      meta: {
        title: "平台账户信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "supplierMoneyRecord",
      name: "general.supplierMoneyRecord",
      component: () => import("@/views/general/supplierMoneyRecord/index.vue"),
      meta: {
        title: "平台资金记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "alreadyMessage",
      name: "general.alreadyMessage",
      component: () => import("@/views/general/alreadyMessage/index.vue"),
      meta: {
        title: "已发消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "broadcastMessage",
      name: "general.broadcastMessage",
      component: () => import("@/views/general/broadcastMessage/index.vue"),
      meta: {
        title: "广播消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "msgTemplate",
      name: "general.msgTemplate",
      component: () => import("@/views/general/msgTemplate/index.vue"),
      meta: {
        title: "消息组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorDocking",
      name: "general.distributorDocking",
      component: () => import("@/views/general/distributorDocking/index.vue"),
      meta: {
        title: "分销商对接",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "receivedMessage",
      name: "general.receivedMessage",
      component: () => import("@/views/general/receivedMessage/index.vue"),
      meta: {
        title: "已收消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "visitorExamine",
      name: "general.visitorExamine",
      component: () => import("@/views/general/visitorExamine/index.vue"),
      meta: {
        title: "提报审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "smsAccount",
      name: "general.smsAccount",
      component: () => import("@/views/general/smsAccount/index.vue"),
      meta: {
        title: "短信账户",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorConfig",
      name: "general.distributorConfig",
      component: () => import("@/views/general/distributorConfig/index.vue"),
      meta: {
        title: "支付配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "rollingMill",
      name: "general.rollingMill",
      component: () => import("@/views/general/rollingMill/index.vue"),
      meta: {
        title: "闸机管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "rollingMillLog",
      name: "general.rollingMillLog",
      component: () => import("@/views/general/rollingMillLog/index.vue"),
      meta: {
        title: "闸机日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "freeVisitors",
      name: "general.freeVisitors",
      component: () => import("@/views/general/freeVisitors/index.vue"),
      meta: {
        title: "免费访客管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "payVisitors",
      name: "general.payVisitors",
      component: () => import("@/views/general/payVisitors/index.vue"),
      meta: {
        title: "付费访客管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "auditflow",
      name: "general.auditflow",
      component: () => import("@/views/general/auditflow/index.vue"),
      meta: {
        title: "审批流程",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "scenicStatement",
      name: "general.scenicStatement",
      component: () => import("@/views/general/scenicStatement/index.vue"),
      meta: {
        title: "景区数据报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "visitorStatement",
      name: "general.visitorStatement",
      component: () => import("@/views/general/visitorStatement/index.vue"),
      meta: {
        title: "访客数据报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "inletgateStatement",
      name: "general.inletgateStatement",
      component: () => import("@/views/general/inletgateStatement/index.vue"),
      meta: {
        title: "入闸数据报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "conductorReport",
      name: "general.conductorReport",
      component: () => import("@/views/distributor/windowReportTemplate/conductorReport"),
      meta: {
        title: "报表", // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
  ],
};
