<template>
  <div class="app">
    <!-- 左侧 -->
    <div class="app-leftview" :class="{ 'app-leftview_show': menuSlide }">
      <div class="app-leftview-content">
        <app-menu class="app-menu" ref="appMenu" :menuname="index"></app-menu>
        <app-menu-mini class="app-menu-mini"></app-menu-mini>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="app-rightview">
      <app-header-tag class="app-nav" @selected="selected" @handleSelectClick="handleSelectClick"></app-header-tag>
      <app-header class="app-header"></app-header>
      <app-content class="app-content"></app-content>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import appMenu from "@/components/app-menu";
import appMenuMini from "@/components/app-menu-mini";
import appHeader from "@/components/app-header";
import appContent from "@/components/app-content";
import appHeaderTag from "@/components/app-header-tag";

export default {
  name: "index",
  components: {
    appMenu,
    appMenuMini,
    appHeader,
    appContent,
    appHeaderTag,
  },
  computed: {
    ...mapState("app", ["menuSlide"]),
  },
  data() {
    return {
      index: "",
    };
  },
  mounted() {},
  methods: {
    selected(e) {
      this.index = e;
    },
    async handleSelectClick(e) {
      this.$refs.appMenu.changemenunameClick(e);
    },
  },
};
</script>

<style lang="scss">
.app {
  height: 100vh;
  width: 100vw;
  display: flex;

  &-leftview {
    width: 56px;
    height: 100%;
    transition: all 500ms;
    position: relative;
    overflow: hidden;
    // z-index: 21;
    z-index: 10;

    &_show {
      width: 240px;
    }

    &-content {
      width: calc(240px + 56px);
      display: flex;
      transform: translateX(-240px);
      transition: 500ms transform;
      height: 100%;
    }

    &_show &-content {
      transform: translateX(0%);
    }
  }

  &-rightview {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 11;
  }

  &-menu {
    height: 100%;
    user-select: none;
  }

  &-nav {
    height: 66px;
  }

  &-header {
    height: 55px;
  }

  &-content {
    flex: 1;
    height: 0;
  }
}
</style>
