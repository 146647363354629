import axios from "axios";
import store from "@/store";
import router from "@/router";
import qs from "qs";

// 
let apiUrl = process.env.VUE_APP_API_URL;
if (localStorage.getItem("api")) {
  apiUrl = localStorage.getItem("api");
}

const result = axios.create({
  baseURL: apiUrl + "api",
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
});

// 添加请求拦截器
result.interceptors.request.use(function (config) {
  // 如果登录了，则携带token
  if (store.getters["user/isLogin"]) {
    config.headers.Authorization = `Bearer ${store.state.user.userInfo.access_token}`;
  }

  // 返回设置
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response 拦截器
result.interceptors.response.use(function (response) {
  switch (response.data.code) {
    case 0:
      throw new Error(response.data.message);
    case 200:
      return response.data;
    case 401:
      // 401为未登录状态，一律跳转到登录页
      // throw new Error(response.data.message)
      router.replace({
        name: "login"
      });
      break;
    case 403:
      throw new Error(response.data.message);
    case 404:
      throw new Error(response.data.message);
    case 429:
      throw new Error(response.data.message);
    case 500:
      throw new Error(response.data.message);
    case 503:
      throw new Error(response.data.message);
    default:
      throw new Error(response.data.message);
  }
}, function (error) {
  console.log(error);
  // store.dispatch('user/logout')
  return Promise.reject(error);
});

export default result;