<script>
export default {
  name: "appUploadFile",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    modelValue: {
      handler(files) {
        this.fileList = files;
      },
    },
  },
  data() {
    return {
      loading: {
        progress: 0,
        status: false,
      },

      fileList: [],
    };
  },
  methods: {
    async upload(optiones) {
      const data = new FormData();
      data.append("file", optiones.file);

      try {
        const result = await this.$request.request({
          url: "/upload/file",
          method: "POST",
          headers: { "Content-type": "multipart/form-data" },
          data,
          onUploadProgress: (e) => {
            this.loading.progress = Math.floor((e.loaded / e.total) * 100);
          },
        });
        this.fileList[0].url = result.data.storage;
        this.$emit("update:modelValue", this.fileList);
      } catch (e) {
        this.$message.error(e.message);
        let fileindex = this.fileList.findIndex((item) => item.uid === optiones.file.uid);
        this.fileList.splice(fileindex, 1);
      }
    },
    onExceed(file, files) {
      console.log(file,files);
      this.$message.warning("最多只能上传一个文件");
    },
  },
};
</script>

<template>
  <div>
    <el-upload :on-exceed="onExceed" v-model:file-list="fileList" class="upload-demo" :limit="1" :http-request="upload">
      <el-button type="primary">点击上传文件</el-button>
      <template #tip>
        <div class="el-upload__tip">文件上传最大支持512MB</div>
      </template>
    </el-upload>
  </div>
</template>

<style lang="scss" scoped></style>
